var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.dataList, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "name",
              "header-align": "center",
              align: "center",
              label: "昵称"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "gender",
              "header-align": "center",
              align: "center",
              label: "性别"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(scope.row.gender === 1 ? "男" : "女") +
                        "\n\t\t\t"
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "mobilePhone",
              "header-align": "center",
              align: "center",
              label: "手机号"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "age",
              "header-align": "center",
              align: "center",
              label: "年龄"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v("\n\t\t\t\t" + _vm._s(scope.row.age) + "岁\n\t\t\t")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "height",
              "header-align": "center",
              align: "center",
              label: "身高"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n\t\t\t\t" + _vm._s(scope.row.height) + "cm\n\t\t\t"
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "weight",
              "header-align": "center",
              align: "center",
              label: "体重"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n\t\t\t\t" + _vm._s(scope.row.weight) + "kg\n\t\t\t"
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "isDefault",
              "header-align": "center",
              align: "center",
              label: "默认就诊人"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(scope.row.isDefault === 1 ? "否" : "是") +
                        "\n\t\t\t"
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }