<template>
	<div>
		<el-table :data="dataList" border style="width: 100%;">
			<el-table-column prop="name" header-align="center" align="center" label="昵称"></el-table-column>
			<el-table-column prop="gender" header-align="center" align="center"  label="性别">
				<template slot-scope="scope">
					{{scope.row.gender === 1 ? '男' : '女'}}
				</template>
			</el-table-column>
			<el-table-column prop="mobilePhone" header-align="center" align="center" label="手机号"></el-table-column>
			<el-table-column prop="age" header-align="center" align="center"  label="年龄">
				<template slot-scope="scope">
					{{scope.row.age}}岁
				</template>
			</el-table-column>
			<el-table-column prop="height" header-align="center" align="center"  label="身高">
				<template slot-scope="scope">
					{{scope.row.height}}cm
				</template>
			</el-table-column>
			<el-table-column prop="weight" header-align="center" align="center"  label="体重">
				<template slot-scope="scope">
					{{scope.row.weight}}kg
				</template>
			</el-table-column>
			<el-table-column prop="isDefault" header-align="center" align="center"  label="默认就诊人">
				<template slot-scope="scope">
					{{scope.row.isDefault === 1 ? '否' : '是'}}
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>
<script>
export default {
	data () {
		return {
			dataList: []
		};
	},
	created () {
		this.search();
	},
	methods: {
		search () {
			this.getData();
		},
		getData () {
			this.$root.request('patientListById', {
				id: +this.$route.query.id
			}).then((data) => {
				if (data) {
					this.dataList = data;
				}
			});
		}
	}
};
</script>