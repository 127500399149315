import { render, staticRenderFns } from "./patient-detail.vue?vue&type=template&id=64b4c5b5&"
import script from "./patient-detail.vue?vue&type=script&lang=js&"
export * from "./patient-detail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/dev/fe/ssl-management/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('64b4c5b5')) {
      api.createRecord('64b4c5b5', component.options)
    } else {
      api.reload('64b4c5b5', component.options)
    }
    module.hot.accept("./patient-detail.vue?vue&type=template&id=64b4c5b5&", function () {
      api.rerender('64b4c5b5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/sys/patient-detail.vue"
export default component.exports